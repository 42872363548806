import React from "react";
import theme from "theme";
import { Theme, Text, Box, Strong, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Про Нас | Favour
			</title>
			<meta name={"description"} content={"На ароматі гри ми пишаємося за підтримку футбольних судів вищого рівня, які гарантують безпечний та чудовий досвід для всіх гравців."} />
			<meta property={"og:title"} content={"Про Нас | Favour"} />
			<meta property={"og:description"} content={"На ароматі гри ми пишаємося за підтримку футбольних судів вищого рівня, які гарантують безпечний та чудовий досвід для всіх гравців."} />
			<meta property={"og:image"} content={"https://ua.skyifair.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://ua.skyifair.com/img/456236236.jpeg"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://ua.skyifair.com/img/456236236.jpeg"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://ua.skyifair.com/img/456236236.jpeg"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://ua.skyifair.com/img/456236236.jpeg"} />
			<link rel={"apple-touch-startup-image"} href={"https://ua.skyifair.com/img/456236236.jpeg"} />
			<meta name={"msapplication-TileImage"} content={"https://ua.skyifair.com/img/456236236.jpeg"} />
			
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Box padding="80px 72px 80px 72px" border-radius="24px" margin="0px 0px 64px 0px" sm-padding="60px 36px 60px 36px">
				<Box
					width="60%"
					display="flex"
					flex-direction="column"
					md-width="100%"
					md-align-items="center"
					md-justify-content="center"
					md-text-align="center"
					lg-width="100%"
				>
					<Text
						margin="0px 0px 16px 0px"
						font="--headline1"
						color="--dark"
						md-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Про Нас
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--greyD3" md-text-align="center">
						На ароматі гри ми пишаємося за підтримку футбольних судів вищого рівня, які гарантують безпечний та чудовий досвід для всіх гравців. Наші доглянуті смоли містять передові дернини та освітлення, створюючи ідеальну обстановку для ваших матчів або тренувань.{"\n\n"}
					</Text>
				</Box>
			</Box>
			<Box
				display="grid"
				lg-flex-wrap="wrap"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="36px 4%"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="12px"
			>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						<Strong>
							Застереження без зусиль
						</Strong>
						{"\n\n"}
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						Застереження футбольного суду з нами - вітер.Просто перевірте нашу доступність, виберіть бажані часові слоти та зробіть бронювання в Інтернеті через нашу зручну для користувачів платформу.Забезпечення вашого місця-це швидкий і безпроблемний процес.{"\n\n"}
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-width="100%" sm-margin="0px 0px 30px 0px">
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						<Strong>
							Доступні варіанти
						</Strong>
						{"\n\n"}
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						Ми твердо віримо, що радість футболу повинна бути в межах досяжності для всіх.Ось чому ми пропонуємо конкурентні варіанти ціноутворення, які задовольняють різні бюджети, будь то оренду Суду на випадковий кікабут чи повномасштабний турнір.{"\n\n"}
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						<Strong>
							Універсальні ігрові поля
						</Strong>
						{"\n\n"}
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						Наші футбольні корти є універсальними та пристосованими для розміщення різних форматів ігор.Незалежно від того, що ви балуєтесь у футболі з 5-ти, 7-а-сторона або 11-а-сторона, у нас є ідеальний простір для вас.{"\n\n"}
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-width="100%" sm-margin="0px 0px 0px 0px">
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						<Strong>
							Спільнота та захоплюючі події
						</Strong>
						{"\n\n"}
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						На користь, ми сприяємо тісно пов'язаній громаді серед любителів футболу.Слідкуйте за спеціальними заходами, турнірами та лігами, що проводяться на наших майданчиках.Приєднуйтесь до дії, зробіть нові знайомі.{"\n\n"}
					</Text>
				</Box>
			</Box>
			<Box min-width="100px" text-align="center" margin="50px 0px 0px 0px">
				<Components.ButtonContact />
			</Box>
		</Section>
		<Section padding="0px 0 80px 0" sm-padding="60px 0px 60px 0px">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					lg-width="100%"
				>
					Ключові переваги
				</Text>
				<Box
					display="flex"
					align-items="flex-start"
					margin="0px 0px 32px 0px"
					lg-width="50%"
					lg-margin="0px 0px 0px 0px"
					lg-padding="0px 16px 0px 0px"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 0px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--darkL1"
						width="49px"
						height="49px"
						border-width="1px"
						border-style="solid"
						border-color="--color-darkL1"
					>
						1
					</Text>
					<Box margin="0px 0px 0px 22px">
						<Text margin="0px 0px 8px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							<Strong>
								Виняткові об'єкти
							</Strong>
							{"\n\n"}
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Faver забезпечує найвищі футбольні майданчики з високоякісним дерном та ретельно підтримуваним освітленням, забезпечуючи безпечний та приємний ігровий досвід для всіх.{"\n\n"}
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					align-items="flex-start"
					margin="0px 0px 32px 0px"
					lg-width="50%"
					lg-margin="0px 0px 0px 0px"
					lg-padding="0px 16px 0px 0px"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 0px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--darkL1"
						width="49px"
						height="49px"
						border-width="1px"
						border-style="solid"
						border-color="--color-darkL1"
					>
						2
					</Text>
					<Box margin="0px 0px 0px 22px">
						<Text margin="0px 0px 8px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							<Strong>
								Доступні варіанти
							</Strong>
							{"\n\n"}
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Ми прагнемо зробити футбол доступним для всіх.Faver пропонує конкурентоспроможні варіанти ціноутворення, щоб відповідати різним бюджетам, незалежно від того, чи граєте ви рекреаційно чи організовуєте турнір.{"\n\n"}
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					align-items="flex-start"
					margin="0px 0px 32px 0px"
					lg-width="50%"
					lg-margin="0px 0px 0px 0px"
					lg-padding="0px 16px 0px 0px"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 0px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--darkL1"
						width="49px"
						height="49px"
						border-width="1px"
						border-style="solid"
						border-color="--color-darkL1"
					>
						3
					</Text>
					<Box margin="0px 0px 0px 22px">
						<Text margin="0px 0px 8px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							<Strong>
								Універсальні ігрові поля
							</Strong>
							{"\n\n"}
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Наші футбольні майданчики пристосовані, підходящі для різних форматів ігор, включаючи 5-ти сторону, 7-а-сторону та футбол 11-а-бік. Виберіть налаштування, яка найбільше відповідає вашим потребам.{"\n\n"}
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					align-items="flex-start"
					margin="0px 0px 32px 0px"
					lg-width="50%"
					lg-margin="0px 0px 0px 0px"
					lg-padding="0px 16px 0px 0px"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 0px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--darkL1"
						width="49px"
						height="49px"
						border-width="1px"
						border-style="solid"
						border-color="--color-darkL1"
					>
						4
					</Text>
					<Box margin="0px 0px 0px 22px">
						<Text margin="0px 0px 8px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							<Strong>
								Професійна допомога
							</Strong>
							{"\n\n"}
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Наша віддана команда завжди готова допомогти вам.Якщо у вас є запитання або потрібна підтримка, ми просто телефонний дзвінок або електронною поштою.
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					align-items="flex-start"
					margin="0px 0px 32px 0px"
					lg-width="50%"
					lg-margin="0px 0px 0px 0px"
					lg-padding="0px 16px 0px 0px"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 0px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--darkL1"
						width="49px"
						height="49px"
						border-width="1px"
						border-style="solid"
						border-color="--color-darkL1"
					>
						5
					</Text>
					<Box margin="0px 0px 0px 22px">
						<Text margin="0px 0px 8px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							<Strong>
								Легкість доступу
							</Strong>
							{"\n\n"}
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Ми надаємо пріоритет доступності, зробивши це прямо для вас, щоб забронювати та насолоджуватися грою у футбол з друзями, родиною чи колегами.
						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-justify-content="center"
				lg-padding="0px 0px 0px 0px"
			>
				<Image
					src="https://ua.skyifair.com/img/6.jpg"
					max-width="100%"
					transform="translateY(10px)"
					transition="transform 0.5s ease-in-out 0s"
					hover-transform="translateY(0px)"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6530dc90e2dd3a0022211ece"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});